import { Card } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import React, { useState } from 'react';
import MaterialTable from '@material-table/core';
import { createTheme, ThemeProvider } from '@mui/material';
import Link from 'next/link';
import { getMedicationStrategyListQuery } from '../../../../queries/medication';
import { getFormattedNumber } from '../../../../utils/currency.utils';
import ThreeDotedTooltip from '../../../shared/ThreeDotedTooltip';
import CustomPagination from '../../../shared/CustomPagination';
import { useQuery } from 'react-query';
import { TableHeading } from '../../../shared/TableHeading';
import { useRecoilState } from 'recoil';
import {
  convertQueryObjectToPath,
  encodeValue,
  formatTextFor_AndRemove_AndCapitalizeLetter
} from '../../../../utils/overview.utils';
import { commonToredirectFromOverview } from '../../../../recoils/common.recoil.state';
import { capitalizeFirstLetter } from '../../../../utils/forms.utils';
import { useRouter } from 'next/router';
const visible = process.env.NEXT_PUBLIC_SHOW_MEDICATION !== 'false';

const LinkToHospital = ({ children, rowData, ...props }) => {
  const router = useRouter();
  const currentMedication = formatTextFor_AndRemove_AndCapitalizeLetter(
    rowData.drugStandard,
    capitalizeFirstLetter
  );
  const recommendedMedication = formatTextFor_AndRemove_AndCapitalizeLetter(
    rowData.drugAlternative,
    capitalizeFirstLetter
  );

  const currentMedicationForRoute = formatTextFor_AndRemove_AndCapitalizeLetter(
    rowData.drugStandard
  );
  const recommendedMedicationForRoute =
    formatTextFor_AndRemove_AndCapitalizeLetter(rowData.drugAlternative);

  let linkPath = `/medication?current=${
    currentMedicationForRoute?.splittedTitle
  }&recommended=${
    recommendedMedicationForRoute?.splittedTitle
  }&showInsights=true&previousPage=${encodeValue({
    previousPage: router.asPath
  })}`;
  if (currentMedication?.route) {
    linkPath += `&currentRoute=${currentMedication?.route}`;
  }

  if (recommendedMedication?.route) {
    linkPath += `&recommendedRoute=${recommendedMedication?.route}`;
  }

  if (rowData?.id) {
    linkPath += `&medicationStrategyId=${rowData?.id}`;
  }

  if (rowData?.primaryTherapeutics) {
    linkPath += `&primaryTherapeutics=${encodeURIComponent(
      JSON.stringify([
        { value: rowData?.normalizedDrg, label: rowData?.primaryTherapeutics }
      ])
    )}`;
  }

  if (
    rowData?.scope?.length &&
    rowData?.scope[0] !== 'unspecified' &&
    rowData?.alternativeNormalizedDrg?.length
  ) {
    linkPath += `&secondaryTherapeutics=${encodeURIComponent(
      JSON.stringify(
        rowData?.alternativeNormalizedDrg?.map((t, ind) => ({
          label: rowData?.scope[ind],
          value: t
        }))
      )
    )}`;
  }

  return (
    <Link
      href={visible ? linkPath : '#'}
      style={{
        textDecoration: 'none',
        color: props?.color ? props?.color : '#000'
      }}
      {...props}
    >
      {children}
    </Link>
  );
};

const OpportunitiesTable = ({
  hospitalId,
  type,
  showAll,
  pageSize = 5,
  healthSystemId
}) => {
  const defaultMaterialTheme = createTheme();
  const { classes } = useStyles();

  const [page, setPage] = useState(1);
  const [paginationChanges, setPaginationChanges] = useState(false);
  const [overviewCommonState, setOverviewCommonState] = useRecoilState(
    commonToredirectFromOverview
  );

  const getQueryParams = () => {
    const query = {};

    query.healthSystemId = healthSystemId;

    if (hospitalId) {
      query.hospitalId = hospitalId;
    }

    query.size = pageSize;
    query.page = page;

    return query;
  };
  let tableData = [];

  const { data, isFetching, isLoading } = useQuery(
    ['getMedicationStrategyList', healthSystemId, hospitalId, type, page],
    () => getMedicationStrategyListQuery(getQueryParams()),
    {
      enabled: type
        ? !!healthSystemId && !!hospitalId
        : !!healthSystemId || !!hospitalId,
      onSuccess: data => {
        setPaginationChanges(false);

        if (
          !!!overviewCommonState?.medication &&
          data.medicationStrategies?.length
        ) {
          const currentMedication = formatTextFor_AndRemove_AndCapitalizeLetter(
            data.medicationStrategies[0]?.drugStandard,
            capitalizeFirstLetter
          );
          const recommendedMedication =
            formatTextFor_AndRemove_AndCapitalizeLetter(
              data.medicationStrategies[0]?.drugAlternative,
              capitalizeFirstLetter
            );

          const queryData = {
            current: currentMedication?.splittedTitle,
            recommended: recommendedMedication?.splittedTitle,
            showInsights: true
          };

          if (currentMedication?.route) {
            queryData.currentRoute = currentMedication?.route;
          }

          if (recommendedMedication?.route) {
            queryData.recommendedRoute = recommendedMedication?.route;
          }

          if (data.medicationStrategies[0]?.id) {
            queryData.medicationStrategyId = data.medicationStrategies[0]?.id;
          }

          if (data.medicationStrategies[0]?.primaryTherapeutics) {
            queryData.primaryTherapeutics = JSON.stringify([
              {
                value: data.medicationStrategies[0]?.normalizedDrg,
                label: data.medicationStrategies[0]?.primaryTherapeutics
              }
            ]);
          }

          if (
            data.medicationStrategies[0]?.scope?.length &&
            data.medicationStrategies[0]?.scope[0] !== 'unspecified' &&
            data.medicationStrategies[0]?.alternativeNormalizedDrg?.length
          ) {
            queryData.secondaryTherapeuticsJSON?.stringify(
              data.medicationStrategies[0]?.alternativeNormalizedDrg?.map(
                (t, ind) => ({
                  label: data.medicationStrategies[0]?.scope[ind],
                  value: t
                })
              )
            );
          }

          setOverviewCommonState({
            ...overviewCommonState,
            medication: convertQueryObjectToPath(queryData)
          });
        }
      },
      onError: err => {
        setPaginationChanges(false);
      }
    }
  );

  if (data) {
    tableData = data.medicationStrategies;
  }

  const cellStyle = {
    border: 'none',
    padding: '10px 15px',
    overflow: 'hidden'
  };

  return (
    <Card className={classes.card} data-cy="Opportunities">
      <ThemeProvider theme={defaultMaterialTheme}>
        <MaterialTable
          style={{ boxShadow: 'none' }}
          // icons={tableIcons}
          icons={{ SortArrow: () => <div /> }}
          isLoading={isFetching || isLoading || paginationChanges}
          columns={[
            {
              title: <TableHeading title="Savings" data-cy="Size" />,
              field: 'opportunitySize',
              headerStyle: { borderRadius: '20px 0 0 20px' },
              cellStyle: {
                ...cellStyle,
                fontWeight: '600',
                borderRadius: '20px 0 0 20px',
                width: 100
              },
              render: rowData => (
                <LinkToHospital
                  data-cy="Savings"
                  rowData={rowData}
                  color="rgb(87, 213, 126)"
                >
                  <div>${getFormattedNumber(rowData?.opportunitySize)}</div>
                </LinkToHospital>
              )
            },
            {
              title: (
                <TableHeading
                  title="Target Medication"
                  data-cy="Target Medication"
                />
              ),
              cellStyle: {
                ...cellStyle,
                width: '15%'
              },
              render: rowData => {
                return (
                  <LinkToHospital data-cy="Target Medication" rowData={rowData}>
                    <div>{`${
                      formatTextFor_AndRemove_AndCapitalizeLetter(
                        rowData.drugStandard,
                        capitalizeFirstLetter
                      )?.title
                    }`}</div>
                  </LinkToHospital>
                );
              }
            },
            {
              title: (
                <TableHeading
                  title="Alternative Medication"
                  data-cy="Alternative Medication"
                />
              ),
              cellStyle: {
                ...cellStyle,
                width: '15%'
              },
              render: rowData => {
                return (
                  <LinkToHospital
                    data-cy="Alternative Medication"
                    rowData={rowData}
                  >
                    <div>{`${
                      formatTextFor_AndRemove_AndCapitalizeLetter(
                        rowData.drugAlternative,
                        capitalizeFirstLetter
                      )?.title
                    }`}</div>
                  </LinkToHospital>
                );
              }
            },
            {
              title: (
                <TableHeading
                  title="Primary Therapeutics "
                  data-cy="Primary Therapeutics "
                />
              ),
              cellStyle: {
                ...cellStyle,
                width: '15%'
              },
              render: rowData => {
                return (
                  <LinkToHospital
                    data-cy="Primary Therapeutics"
                    rowData={rowData}
                  >
                    <div>{rowData.primaryTherapeutics}</div>
                  </LinkToHospital>
                );
              }
            },
            {
              title: (
                <TableHeading
                  title="Secondary Therapeutics"
                  data-cy="Secondary Therapeutics"
                />
              ),
              width: '15%',
              field: 'scope',
              cellStyle,
              render: rowData => (
                <LinkToHospital
                  data-cy="Secondary Therapeutics
"
                  rowData={rowData}
                >
                  {rowData?.scope?.join(',')}
                </LinkToHospital>
              )
            },
            {
              title: <TableHeading title="Facility" data-cy="Facility" />,
              field: 'facility',

              cellStyle,
              render: rowData => (
                <LinkToHospital data-cy="Facility" rowData={rowData}>
                  {rowData?.facility}
                </LinkToHospital>
              )
            },
            {
              title: <TableHeading title="Status" data-cy="Status" />,
              field: 'status',
              headerStyle: { padding: '0 5px', textAlign: 'end' },
              cellStyle: {
                ...cellStyle,
                textAlign: 'end',
                padding: '10px 0px'
              },
              render: rowData => (
                <LinkToHospital data-cy="Status" rowData={rowData}>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'end',
                      width: '100%'
                    }}
                  >
                    {' '}
                    <div className={classes.status}>
                      {'Not Reviewed' || rowData.status}
                    </div>
                  </div>
                </LinkToHospital>
              )
            },
            {
              title: '',
              field: '',
              headerStyle: { borderRadius: '0 20px 20px 0' },
              cellStyle: {
                ...cellStyle,
                borderRadius: '0 20px 20px 0',
                textAlign: 'end'
              },
              render: rowData => <ThreeDotedTooltip />
            }
          ]}
          data={tableData}
          options={{
            draggable: false,
            search: false,
            toolbar: false,
            emptyRowsWhenPaging: tableData?.length >= 20 ? true : false, // To avoid of having empty rows
            pageSize: pageSize ? pageSize : 5,
            pageSizeOptions: [18, 36, 54, 72],
            headerStyle: {
              backgroundColor: '#397ed0',
              color: '#FFF',
              padding: '8px 10px',
              border: 'none'
            },
            rowStyle: x => {
              if (x.tableData.index % 2) {
                return {
                  backgroundColor: '#eff5ff',
                  height: 20
                };
              } else {
                return {
                  height: 20
                };
              }
            }
          }}
          components={{
            Pagination: props => {
              return (
                <CustomPagination
                  props={{
                    count: tableData?.length * page + pageSize,
                    page: page - 1, // because default material table props page starts from 0
                    rowsPerPage: pageSize,
                    setPage: setPage,
                    customPagination: true,
                    setPaginationChanges
                  }}
                />
              );
            }
          }}
        />
      </ThemeProvider>
    </Card>
  );
};

const useStyles = makeStyles()(theme => ({
  card: {
    padding: '0 10px'
  },
  table: {
    border: 'none'
  },
  toolbar: {
    padding: '0 5px',
    height: 70,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  title: {
    fontSize: 20,
    fontWeight: '500'
  },
  tabContainer: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: 10,
    overflow: 'hidden'
  },
  tabItem: {
    padding: '10px 10px',
    fontSize: '1rem',
    minWidth: 140,
    cursor: 'pointer',
    backgroundColor: '#eff5ff',
    color: '#397ed0',
    textAlign: 'center',
    fontWeight: '500'
  },
  activeTab: {
    backgroundColor: '#397ed0',
    color: '#fff'
  },
  button: {
    padding: '7px 10px',
    fontSize: '1rem',
    cursor: 'pointer',
    width: 100,
    backgroundColor: '#eff5ff',
    color: '#397ed0',
    textAlign: 'center',
    borderRadius: 10,
    fontWeight: '500'
  },
  status: {
    backgroundColor: '#f4433629',
    textAlign: 'center',
    width: 'fit-content',
    padding: '0 5px',
    borderRadius: 3,
    color: 'red'
  },
  editIcons: {
    display: 'flex',
    justifyContent: 'space-evenly'
  },
  icon: {
    backgroundColor: '#F3F8FF',
    textAlign: 'center',
    width: 'fit-content',
    padding: '1px 9px',
    borderRadius: 8
  },
  link: {
    cursor: 'pointer',
    textDecoration: 'none',
    color: 'inherit'
  }
}));

export default OpportunitiesTable;

import { Card } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import React, { useState } from 'react';
import MaterialTable, { MTablePagination } from '@material-table/core';
import { createTheme, ThemeProvider } from '@mui/material';
import { getHighScorePhysicians } from '../../../../queries/physician';
import Link from 'next/link';
import ThreeDotedTooltip from '../../../shared/ThreeDotedTooltip';
import CustomPagination from '../../../shared/CustomPagination';
import { useQuery } from 'react-query';
import { TableHeading } from '../../../shared/TableHeading';
import { useRecoilState } from 'recoil';
import { currentHealthSystemIdState } from '../../../../recoils/analysis.recoil.state';
import { getFormattedNumber } from '../../../../utils/currency.utils';
import { useRouter } from 'next/router';
import { encodeValue } from '../../../../utils/overview.utils';
const visible = process.env.NEXT_PUBLIC_SHOW_PHYSICIAN !== 'false';

const LinkToHospital = ({ children, rowData, ...props }) => {
  const router = useRouter();

  return (
    <Link
      href={
        visible
          ? `/physician?q=${rowData.physicianId}&modeOutlierId=${
              rowData.id
            }&previousPage=${encodeValue({ previousPage: router.asPath })}`
          : '#'
      }
      style={{ textDecoration: 'none', color: '#000' }}
      {...props}
    >
      {children}
    </Link>
  );
};

const PhysiciansTable = ({ hospitalId, type, pageSize = 5 }) => {
  const defaultMaterialTheme = createTheme();
  const { classes } = useStyles();
  const [healthSystem] = useRecoilState(currentHealthSystemIdState);
  const healthSystemId = healthSystem?.value;

  const [page, setPage] = useState(1);
  const [paginationChanges, setPaginationChanges] = useState(false);

  let tableData = [];
  const getQueryParams = () => {
    const query = {};

    query.healthSystemId = healthSystemId;

    if (hospitalId) {
      query.hospitalId = hospitalId;
    }

    query.size = pageSize;
    query.page = page;
    return query;
  };

  const { data, isFetching, isLoading } = useQuery(
    ['getHighScorePhysicians', healthSystemId, hospitalId, type, page],
    () => getHighScorePhysicians(getQueryParams()),
    {
      enabled: type
        ? !!healthSystemId && !!hospitalId
        : !!healthSystemId || !!hospitalId,
      onSuccess: data => {
        setPaginationChanges(false);
      },
      onError: err => {
        setPaginationChanges(false);
      }
    }
  );

  if (data) {
    tableData = data.highScorePhysicians;
  }

  const cellStyle = {
    border: 'none',
    padding: '10px 15px',
    overflow: 'hidden'
  };

  return (
    <Card className={classes.card} data-cy="Physicians">
      <ThemeProvider theme={defaultMaterialTheme}>
        <MaterialTable
          style={{ boxShadow: 'none' }}
          // icons={tableIcons}
          icons={{ SortArrow: () => <div /> }}
          isLoading={isFetching || isLoading || paginationChanges}
          columns={[
            {
              title: <TableHeading title="Score" data-cy="Score" />,
              field: 'score',
              headerStyle: { borderRadius: '20px 0 0 20px' },
              cellStyle: {
                ...cellStyle,
                fontWeight: '600',
                borderRadius: '20px 0 0 20px',
                width: '10%'
              },
              render: rowData => (
                <LinkToHospital data-cy="Score" rowData={rowData}>
                  <div style={{color:'rgb(255, 87, 51)'}} className={classes.link}>{rowData?.score}</div>
                </LinkToHospital>
              )
            },
            {
              title: <TableHeading title="Physician" data-cy="Physician" />,
              field: 'prescriber',
              cellStyle: { ...cellStyle, width: '20%' },
              render: rowData => (
                <LinkToHospital data-cy="Physician" rowData={rowData}>
                  <div className={classes.link}>{rowData.prescriber}</div>
                </LinkToHospital>
              )
            },
            {
              title: (
                <TableHeading
                  title="Med Spend (monthly)"
                  data-cy="Med Spend (monthly)"
                />
              ),
              field: 'monthlyMedicationSpend',
              cellStyle: { ...cellStyle, width: '30%' },
              render: rowData => (
                <LinkToHospital data-cy="Med Spend (monthly)" rowData={rowData}>
                  <div className={classes.link}>
                    ${getFormattedNumber(rowData.monthlyMedicationSpend)}
                  </div>
                </LinkToHospital>
              )
            },
            {
              title: (
                <TableHeading
                  title="Patient Volume (monthly)"
                  data-cy="Patient Volume (monthly)"
                />
              ),
              field: 'monthlyPatientCensus',
              cellStyle: { ...cellStyle, width: '20%' },
              render: rowData => (
                <LinkToHospital
                  data-cy="Patient Volume (monthly)"
                  rowData={rowData}
                >
                  <div className={classes.link}>
                    {rowData?.monthlyPatientCensus}
                  </div>
                </LinkToHospital>
              )
            },
            // {
            //   title: (
            //     <TableHeading
            //       title="Length Of Stay (Avg.)"
            //       data-cy="Length Of Stay (Avg.)"
            //     />
            //   ),
            //   field: 'monthlyPatientCensus',
            //   cellStyle: { ...cellStyle, width: '20%' },
            //   render: rowData => (
            //     <LinkToHospital
            //       data-cy="Length Of Stay (Avg.)"
            //       rowData={rowData}
            //     >
            //       <div className={classes.link}>
            //         {rowData?.lengthOfStay}
            //       </div>
            //     </LinkToHospital>
            //   )
            // },
            {
              title: <TableHeading title="Status" data-cy="Status" />,
              field: 'status',
              headerStyle: { textAlign: 'end', padding: '0 15px' },
              cellStyle: { ...cellStyle, width: '20%', padding: '0 10px' },
              render: rowData => (
                <LinkToHospital data-cy="Status" rowData={rowData}>
                  <div className={classes.status}>
                    {'Not Reviewed' || rowData.status}
                  </div>
                </LinkToHospital>
              )
            },
            {
              title: '',
              field: '',
              headerStyle: { borderRadius: '0 20px 20px 0' },
              cellStyle: {
                ...cellStyle,
                borderRadius: '0 20px 20px 0',
                textAlign: 'end'
              },
              render: rowData => <ThreeDotedTooltip />
            }
          ]}
          data={tableData}
          options={{
            search: false,
            toolbar: false,
            draggable: false,
            emptyRowsWhenPaging: tableData?.length >= 20 ? true : false, // To avoid of having empty rows
            pageSize: pageSize ? pageSize : 5,
            pageSizeOptions: [18, 36, 54, 72],
            headerStyle: {
              backgroundColor: '#397ed0',
              color: '#FFF',
              padding: '8px 10px',
              border: 'none'
            },
            rowStyle: x => {
              if (x.tableData.index % 2) {
                return {
                  backgroundColor: '#eff5ff',
                  height: 20
                };
              } else {
                return {
                  height: 20
                };
              }
            }
          }}
          components={{
            Pagination: props => {
              return (
                <CustomPagination
                  props={{
                    count: tableData?.length * page + pageSize,
                    page: page - 1, // because default material table props page starts from 0
                    rowsPerPage: pageSize,
                    setPage: setPage,
                    customPagination: true,
                    setPaginationChanges
                  }}
                />
              );
            }
            // Pagination: props => {
            //   return (
            //     <div>
            //       <MTablePagination {...props} />
            //     </div>
            //   );
            // }
          }}
        />
      </ThemeProvider>
    </Card>
  );
};

const useStyles = makeStyles()(theme => ({
  card: {
    padding: '0 15px'
  },
  table: {
    border: 'none'
  },
  toolbar: {
    padding: '0 5px',
    height: 70,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  title: {
    fontSize: 20,
    fontWeight: '500'
  },
  tabContainer: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: 10,
    overflow: 'hidden'
  },
  tabItem: {
    padding: '10px 10px',
    fontSize: '1rem',
    minWidth: 140,
    cursor: 'pointer',
    backgroundColor: '#eff5ff',
    color: '#397ed0',
    textAlign: 'center',
    fontWeight: '500'
  },
  activeTab: {
    backgroundColor: '#397ed0',
    color: '#fff'
  },
  button: {
    padding: '7px 10px',
    fontSize: '1rem',
    cursor: 'pointer',
    width: 100,
    backgroundColor: '#eff5ff',
    color: '#397ed0',
    textAlign: 'center',
    borderRadius: 10,
    fontWeight: '500'
  },
  status: {
    backgroundColor: '#f4433629',
    textAlign: 'center',
    width: 'fit-content',
    padding: '0 10px',
    borderRadius: 3,
    float: 'right',
    color: 'red'
  },
  editIcons: {
    display: 'flex',
    justifyContent: 'space-evenly'
  },
  icon: {
    backgroundColor: '#F3F8FF',
    textAlign: 'center',
    width: 'fit-content',
    padding: '1px 9px',
    borderRadius: 8
  },
  link: {
    cursor: 'pointer'
  }
}));

export default PhysiciansTable;

import * as React from 'react';
import MenuItem from '@mui/material/MenuItem';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

import NewTooltip from './Tooltip';
import {
  CopyIcon,
  DeleteIcon,
  Details,
  PenIcon,
  SaveIcon,
  ThreeDotsIcon
} from '../../icons/icons';

const names = ['In Progress', 'Completed', 'Rejected'];

const usePlaceholderStyles = makeStyles()(theme => ({
  placeholder: {
    color: '#387ED0'
  }
}));

const Placeholder = ({ children }) => {
  const classes = usePlaceholderStyles();
  return <div className={classes.placeholder}>{children}</div>;
};

export default function ThreeDotedTooltip(props) {
  const [personName, setPersonName] = React.useState([]);
  const { classes } = useStyles();
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();

  const icons = [
    <PenIcon />,
    <CopyIcon />,
    <SaveIcon />,
    <Details />,
    <DeleteIcon />
  ];

  const handleTooltipOpen = () => {
    setOpen(!open);
  };
  const handleTooltipClose = () => {
    setOpen(false);
  };
  const handleChange = event => {
    const {
      target: { value }
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    );
  };

  return (
    <NewTooltip
      placement="top"
      handleTooltipClose={handleTooltipClose}
      handleTooltipOpen={handleTooltipOpen}
      open={open}
      title={
        <div
          style={{
            position: 'absolute',
            zIndex: '5000',
            top:"-40px",
            right: '-0px',
            background: 'white',
            borderRadius: '8px'
          }}
        >
          {names.map((name, index) => (
            <MenuItem style={{ padding: '8px 15px' }} key={name} value={name}>
              {icons[index]}
              <span style={{ marginLeft: 10, color: '#465671' }}>{name}</span>
            </MenuItem>
          ))}
        </div>
      }
    >
      <div style={{ height: '20px', position: 'relative' }}>
        <ThreeDotsIcon />
      </div>
    </NewTooltip>
  );
}

const useStyles = makeStyles()({
  customOutline: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#387ED0'
    }
  }
});

import { Card } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import React, { useState } from 'react';
import MaterialTable from '@material-table/core';
import { createTheme, ThemeProvider } from '@mui/material';
import Link from 'next/link';
import { getFormattedNumber } from '../../../../utils/currency.utils';
import { getModelUtilizationListQuery } from '../../../../queries/utilization';
import ThreeDotedTooltip from '../../../shared/ThreeDotedTooltip';
import CustomPagination from '../../../shared/CustomPagination';
import { useQuery } from 'react-query';
import { TableHeading } from '../../../shared/TableHeading';
import { useRecoilState } from 'recoil';
import { currentHealthSystemIdState } from '../../../../recoils/analysis.recoil.state';
import { encodeValue } from '../../../../utils/overview.utils';
import { useRouter } from 'next/router';
const visible = process.env.NEXT_PUBLIC_SHOW_UTILIZATION !== 'false';

const LinkToHospital = ({ children, rowData, ...props }) => {
  const router = useRouter();
  return (
    <Link
      href={
        visible
          ? `/utilization/compare?q=${
              rowData?.id
            }&therapeuticName=${encodeValue(
              rowData?.therapeuticDescription
            )}&previousPage=${encodeValue({ previousPage: router.asPath })}`
          : '#'
      }
      style={{ textDecoration: 'none', height: '10px' }}
      {...props}
    >
      <div
        className={`compare-${rowData?.id}`}
        style={{
          height: 'auto',
          width: '100%',
          color: props?.color ? props?.color : '#000'
        }}
      >
        {children}
      </div>
    </Link>
  );
};

const UtilizationTable = ({ hospitalId, type, pageSize = 5 }) => {
  const [healthSystem] = useRecoilState(currentHealthSystemIdState);
  const healthSystemId = healthSystem?.value;
  const defaultMaterialTheme = createTheme();
  const { classes } = useStyles();

  const [page, setPage] = useState(1);
  const [paginationChanges, setPaginationChanges] = useState(false);

  let tableData = [];
  const getQueryParams = () => {
    const query = {};

    query.healthSystemId = healthSystemId;

    if (hospitalId) {
      query.hospitalId = hospitalId;
    }

    query.size = pageSize;
    query.page = page;

    return query;
  };

  const { data, isFetching, isLoading } = useQuery(
    ['getModelUtilizationListQuery', healthSystemId, hospitalId, type, page],
    () => getModelUtilizationListQuery(getQueryParams()),
    {
      enabled: type
        ? !!healthSystemId && !!hospitalId
        : !!healthSystemId || !!hospitalId,
      onSuccess: data => {
        setPaginationChanges(false);
      },
      onError: err => {
        setPaginationChanges(false);
      }
    }
  );

  if (data) {
    tableData = data.opportunities;
  }

  const cellStyle = {
    border: 'none',
    padding: '10px 15px',
    overflow: 'hidden'
  };

  return (
    <Card className={classes.card} data-cy="Utilization">
      <ThemeProvider theme={defaultMaterialTheme}>
        <MaterialTable
          style={{ boxShadow: 'none' }}
          // icons={tableIcons}
          icons={{ SortArrow: () => <div /> }}
          isLoading={isFetching || isLoading || paginationChanges}
          columns={[
            {
              title: <TableHeading title="Score" data-cy="Score" />,
              field: 'score',
              headerStyle: { borderRadius: '20px 0 0 20px' },
              cellStyle: {
                ...cellStyle,
                fontWeight: '600',
                width: '10%',
                borderRadius: '20px 0 0 20px'
              },
              render: rowData => (
                <LinkToHospital
                  data-cy="Score"
                  rowData={rowData}
                  color="rgb(255, 87, 51)"
                >
                  <div className={classes.link}>{rowData.score}</div>
                </LinkToHospital>
              )
            },
            {
              title: (
                <TableHeading
                  title="Therapeutics / Medication"
                  data-cy="Therapeutics / Medication"
                />
              ),
              field: 'therapeuticDescription',
              cellStyle: { ...cellStyle, width: '16%' },
              render: rowData => (
                <LinkToHospital
                  data-cy="Therapeutics / Medication"
                  rowData={rowData}
                >
                  <div
                    className={classes.link}
                    style={
                      !rowData?.therapeuticDescription
                        ? { padding: '10px' }
                        : {}
                    }
                  >
                    {rowData.therapeuticDescription}
                  </div>
                </LinkToHospital>
              )
            },
            {
              title: <TableHeading title="Context" data-cy="Context" />,
              field: 'cause',
              cellStyle: { ...cellStyle, width: '16%' },
              render: rowData => (
                <LinkToHospital data-cy="Context" rowData={rowData}>
                  <div className={classes.link}>{rowData.context}</div>
                </LinkToHospital>
              )
            },
            {
              title: <TableHeading title="Root Cause" data-cy="Root Cause" />,
              field: 'trend',
              cellStyle: { ...cellStyle, width: '16%' },
              render: rowData => (
                <LinkToHospital data-cy="Root Cause" rowData={rowData}>
                  <div className={classes.link}>{rowData?.cause}</div>
                </LinkToHospital>
              )
            },
            {
              title: (
                <TableHeading
                  title="Projected Impact"
                  data-cy="Projected Impact"
                />
              ),
              field: 'monthlyPatientCensus',
              cellStyle: { ...cellStyle, width: '16%' },
              render: rowData => (
                <LinkToHospital data-cy="Projected Impact" rowData={rowData}>
                  <div>${getFormattedNumber(rowData.impact)}</div>
                </LinkToHospital>
              ),
              customSort: (a, b) => a.impact - b.impact
            },
            {
              title: <TableHeading title="Scope" data-cy="Scope" />,
              field: 'scope',
              cellStyle: { ...cellStyle, width: '10%' },
              render: rowData => (
                <LinkToHospital data-cy="Scope" rowData={rowData}>
                  <div
                    className={classes.link}
                    style={!rowData?.scope ? { padding: '10px' } : {}}
                  >
                    {rowData?.scope}
                  </div>
                </LinkToHospital>
              )
            },
            {
              title: <TableHeading title="Status" data-cy="Status" />,
              field: 'status',
              cellStyle: { ...cellStyle, width: '20%', padding: '0 5px' },
              headerStyle: { textAlign: 'end', padding: '0 15px' },
              render: rowData => (
                <LinkToHospital data-cy="Status" rowData={rowData}>
                  <div className={classes.status}>
                    {'Not Reviewed' || rowData.status}
                  </div>
                </LinkToHospital>
              )
            },
            {
              title: '',
              field: '',
              headerStyle: { borderRadius: '0 20px 20px 0' },
              cellStyle: {
                ...cellStyle,
                borderRadius: '0 20px 20px 0',
                textAlign: 'end'
              },
              render: rowData => (
                <span style={{ height: '18px' }}>
                  <ThreeDotedTooltip />
                </span>
              )
            }
          ]}
          data={tableData}
          options={{
            sorting: false,
            search: false,
            draggable: false,
            emptyRowsWhenPaging: tableData?.length >= 20 ? true : false, // To avoid of having empty rows
            pageSize: pageSize ? pageSize : 5,
            pageSizeOptions: [18, 36, 54, 72],
            toolbar: false,
            headerStyle: {
              backgroundColor: '#397ed0',
              color: '#FFF',
              padding: '8px 10px',
              border: 'none'
            },
            rowStyle: x => {
              if (x.tableData.index % 2) {
                return {
                  backgroundColor: '#eff5ff',
                  height: 20
                };
              } else {
                return {
                  height: 20
                };
              }
            }
          }}
          components={{
            Pagination: props => {
              return (
                <CustomPagination
                  props={{
                    count: tableData?.length * page + pageSize,
                    page: page - 1, // because default material table props page starts from 0
                    rowsPerPage: pageSize,
                    setPage: setPage,
                    customPagination: true,
                    setPaginationChanges
                  }}
                />
              );
            }
            // Pagination: props => {
            //   return (
            //     <div>
            //       <MTablePagination {...props} />
            //     </div>
            //   );
            // }
          }}
        />
      </ThemeProvider>
    </Card>
  );
};

const useStyles = makeStyles()(theme => ({
  card: {
    padding: '0 10px'
  },
  table: {
    border: 'none'
  },
  toolbar: {
    padding: '0 5px',
    height: 70,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  title: {
    fontSize: 20,
    fontWeight: '500'
  },
  tabContainer: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: 10,
    overflow: 'hidden'
  },
  tabItem: {
    padding: '10px 10px',
    fontSize: '1rem',
    minWidth: 140,
    cursor: 'pointer',
    backgroundColor: '#eff5ff',
    color: '#397ed0',
    textAlign: 'center',
    fontWeight: '500'
  },
  activeTab: {
    backgroundColor: '#397ed0',
    color: '#fff'
  },
  button: {
    padding: '7px 10px',
    fontSize: '1rem',
    cursor: 'pointer',
    width: 100,
    backgroundColor: '#eff5ff',
    color: '#397ed0',
    textAlign: 'center',
    borderRadius: 10,
    fontWeight: '500'
  },
  status: {
    backgroundColor: '#f4433629',
    textAlign: 'center',
    width: 'fit-content',
    padding: '0 10px',
    float: 'right',
    borderRadius: 3,
    color: 'red'
  },
  editIcons: {
    display: 'flex',
    justifyContent: 'space-evenly'
  },
  icon: {
    backgroundColor: '#F3F8FF',
    textAlign: 'center',
    width: 'fit-content',
    padding: '1px 9px',
    borderRadius: 8
  },
  link: {
    cursor: 'pointer',
    width: '100%'
  }
}));

export default UtilizationTable;

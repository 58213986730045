import { Card, Grid, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import React, { useState } from 'react';
import clsx from 'clsx';
import PhysiciansTable from './PhysiciansTable';
import OpportunitiesTable from './OpportunutiesTable';
import UtilizationTable from './UtilizationTable';
import { checkRequiredResponsiveSizeUsingCssMediaQuery } from '../../../../utils/resposive.utils';
import Link from 'next/link';
import { useRouter } from 'next/router';

const tabs = [
  {
    label: 'Opportunities'
  },
  {
    label: 'Utilization'
  },
  {
    label: 'Physicians'
  }
];

const OverviewInsights = ({
  hospitalId,
  type,
  showAll,
  pageSize,
  healthSystemId
}) => {
  const router = useRouter();
  const [activeTab, setActiveTab] = useState(
    router?.query?.activeTab || 'Opportunities'
  );
  const { classes } = useStyles();

  const onChangeTab = (tab, data) => {
    setActiveTab(tab);
  };

  const matches =
    checkRequiredResponsiveSizeUsingCssMediaQuery('(max-width:550px)');

  const manualStyles = matches
    ? { margin: '5px 0px', justifyContent: 'center' }
    : {};

  return (
    <Card className={classes.card} data-cy="insights">
      <Grid container className={classes.toolbar}>
        <Grid
          item
          xs={4}
          sm={4}
          md={4}
          lg={4}
          className={classes.center}
          style={manualStyles}
        >
          <div className={classes.title}>Insights</div>
        </Grid>

        {matches && !showAll && (
          <Grid item xs={6} style={{ marginTop: 15, marginBottom: 10 }}>
            <Link
              href={`/overview/insights?activeTab=${activeTab}&healthSystemId=${healthSystemId}${
                hospitalId ? `&hospitalId=${hospitalId}` : ''
              }`}
              data-cy="view-all"
            >
              <div className={classes.button}>View All</div>
            </Link>
          </Grid>
        )}

        <Grid
          item
          xs={12}
          sm={4}
          md={4}
          lg={4}
          className={classes.center}
          style={{ justifyContent: 'center', ...manualStyles }}
          data-cy="Insight Tabs"
        >
          <Grid container justifyContent={'center'} overflow="hidden">
            <div className={classes.tabContainer}>
              {tabs?.map(({ label, data }) => (
                <div
                  data-cy={label}
                  key={label}
                  className={clsx({
                    [classes.tabItem]: true,
                    [classes.activeTab]: activeTab === label
                  })}
                  onClick={() => onChangeTab(label, data)}
                >
                  {label}
                </div>
              ))}
            </div>
          </Grid>
        </Grid>
        {!matches && (
          <Grid
            item
            xs={12}
            sm={4}
            md={4}
            lg={4}
            className={classes.center}
            style={{ justifyContent: 'flex-end', ...manualStyles }}
          >
            {!showAll && (
              <Link
                href={`/overview/insights?activeTab=${activeTab}&healthSystemId=${healthSystemId}${
                  hospitalId ? `&hospitalId=${hospitalId}` : ''
                }`}
                data-cy="view-all"
              >
                <div className={classes.button}>View All</div>
              </Link>
            )}
          </Grid>
        )}
      </Grid>
      {activeTab === 'Opportunities' && (
        <OpportunitiesTable
          hospitalId={hospitalId}
          showAll={showAll}
          type={type}
          pageSize={pageSize}
          healthSystemId={healthSystemId}
        />
      )}
      {activeTab === 'Utilization' && (
        <UtilizationTable
          hospitalId={hospitalId}
          type={type}
          showAll={showAll}
          pageSize={pageSize}
          healthSystemId={healthSystemId}
        />
      )}
      {activeTab === 'Physicians' && (
        <PhysiciansTable
          hospitalId={hospitalId}
          type={type}
          showAll={showAll}
          pageSize={pageSize}
          healthSystemId={healthSystemId}
        />
      )}
    </Card>
  );
};

const useStyles = makeStyles()(theme => {
  return {
    card: {
      padding: '0 15px'
    },
    table: {
      border: 'none'
    },
    toolbar: {
      padding: '0 5px',
      minHeight: 70,
      display: 'flex',
      alignItems: 'center'
      // justifyContent: 'space-between'
    },
    title: {
      fontSize: 20,
      fontWeight: '600',
      color: '#465671'
    },
    tabContainer: {
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'row',
      alignItems: 'center',
      borderRadius: 10,
      overflow: 'hidden'
    },
    tabItem: {
      padding: '10px 10px',
      minWidth: 140,
      cursor: 'pointer',
      backgroundColor: '#eff5ff',
      color: '#397ed0',
      textAlign: 'center',
      fontWeight: '500'
    },
    activeTab: {
      backgroundColor: '#397ed0 !important',
      color: '#fff !important'
    },
    button: {
      padding: '5px 12px',
      cursor: 'pointer',
      backgroundColor: '#eff5ff',
      color: '#397ed0',
      textAlign: 'center',
      borderRadius: 10,
      width: 90,
      float: 'right'
    },
    status: {
      backgroundColor: '#f4433629',
      textAlign: 'center',
      width: 'fit-content',
      padding: '0 10px',
      borderRadius: 3,
      color: 'red'
    },
    editIcons: {
      display: 'flex',
      justifyContent: 'space-evenly'
    },
    icon: {
      backgroundColor: '#F3F8FF',
      textAlign: 'center',
      width: 'fit-content',
      padding: '1px 9px',
      borderRadius: 8
    }
  };
});

export default OverviewInsights;
